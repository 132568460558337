$(document).ready ->
  $(document).on 'change', '#exam_id', (e) ->
    e.preventDefault()
    $.ajax
      url: '/app/dashboards/get_users/' + $(this).val()
      type: 'GET'
      dataType: 'json'
      success: (response) ->
        prompt = 
          name: 'Select User'
        arr = response.data
        arr.splice 0, 0, prompt
        optionHtml = ''
        $.each arr, (key, element) ->
          optionHtml += """<option value="#{element.id}">#{element.name}</option>"""
        $('#user_id').html optionHtml
        $('#user_id').removeAttr 'disabled'
        $('#chartElement').addClass('d-none')
        $('#legend').addClass('d-none')
        $('#legend').empty()

  # $(document).on 'change', '#user_id', (e) ->
  #   e.preventDefault()
  #   user_id = $(this).val()
  #   exam_id = $('#exam_id').val()

  #   $.ajax
  #     url: """/app/dashboards/show_graph/#{user_id}/#{exam_id}"""
  #     type: 'GET'
  #     dataType: 'json'
  #     success: (response) ->
  #       `var data`

  #       drawChart = ->
  #         `var data`
  #         `var i`
  #         data = google.visualization.arrayToDataTable([
  #           [
  #             'Categorizes'
  #             'Result'
  #           ]
  #           [
  #             'Correct'
  #             data_array.correct
  #           ]
  #           [
  #             'Incorrect'
  #             data_array.incorrect
  #           ]
  #         ])
  #         options = 
  #           title: 'User Exam Report'
  #           pieHole: 0.4
  #           colors: [
  #             '#0ee044'
  #             '#e0240e'
  #           ]
  #         chart = new (google.visualization.PieChart)(document.getElementById('donutchart'))
  #         chart.draw data, options
  #         total = 0
  #         i = 0
  #         while i < data.getNumberOfRows()
  #           total += data.getValue(i, 1)
  #           i++
  #         legend = document.getElementById('legend')
  #         legItem = []
  #         colors = [
  #           '#0ee044'
  #           '#e0240e'
  #         ]
  #         i = 0
  #         while i < data.getNumberOfRows()
  #           label = data.getValue(i, 0)
  #           value = data.getValue(i, 1)
  #           #var percent = Number(100 * value / total).toFixed(1);
  #           # This will create legend list for the display
  #           legItem[i] = document.createElement('span')
  #           legItem[i].id = 'legend_' + data.getValue(i, 0)
  #           legItem[i].innerHTML = '<div class="legendMarker"> <span style="color:' + colors[i] + ';">' + label + ': ' + value + '/ ' + data_array.total_marks + '</span></div>'
  #           legend.appendChild legItem[i]
  #           i++
  #         return

  #       $('#legend').empty()
  #       data = response.data
  #       data_array = data
  #       document.getElementById('chartElement').classList.remove 'd-none'
  #       document.getElementById('legend').classList.remove 'd-none'
  #       google.charts.load 'current', packages: [ 'corechart' ]
  #       google.charts.setOnLoadCallback drawChart
  #       $('#donutchart').removeClass 'd-none'
  #       return
  #   return

  # # ---
  # generated by js2coffee 2.2.0