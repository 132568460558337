$(document).ready ->
  $(document).on 'click', '[data-link-to-remove-field]', (e) ->
    e.preventDefault()
    $(this).prev('input[type=hidden]').val('1')
    target = $(this).data('link-to-remove-field')
    $field = $(this).closest(target)
    $field.addClass('d-none')

  $(document).on 'click', '[data-link-to-add-field]', (e) ->
    e.preventDefault()
    time         = new Date().getTime()
    regexp       = new RegExp($(this).data('link-to-add-field-id'), 'g')
    fields_html  = $(this).data('link-to-add-field').replace(regexp, time)
    $(this).before(fields_html)

  calculate_total_marks = ->
    sum = 0
    $('.section_fields').each (i, obj) ->
      count = $(obj).find(".section_question_count").val()
      marks = $(obj).find(".section_marks").val()
      sum = sum + (count * marks)
    $("#total_marks").val(sum)

  $(document).on 'blur', '.section_question_count, .section_marks', (e) ->
    calculate_total_marks()

  $(document).on 'click', '.btnRemove', (e) ->
    e.preventDefault()
    $(this).parents('.form-group').remove()

  $(document).on 'click', '.btnAdd', (e) ->
    e.preventDefault()
    row = """
      <div class="form-group">
      <div class="row">
      <div class="col-10">
      <input type="text" name="exam[features][]" id="exam_features_" value="" class="form-control">
      </div>
      <div class="col-2">
      <button name="button" type="submit" class="btn btn-danger float-right btnRemove">Remove</button>
      </div>
      </div>
      </div>
      """
    $('.features_list').append(row)

  $(document).on 'click', '.btnRemoveSection', (e) ->
    e.preventDefault()
    $(this).parents('.form-group').remove()

  $(document).on 'click', '.btnAddSection', (e) ->
    e.preventDefault()
    sections_list = JSON.parse($("#sections_list").val())
    
    row = """
      <div class="form-group">
      <div class="row">
      <div class="col-10">
      <select type="text" name="question[section_ids][]" class="form-control select2">
      """

    row += """<option value="">Select Section</option>"""
    sections_list.forEach (value, index) ->
      row += """<option value="#{value.id}">#{value.text}</option>"""

    row += 
      """
      </select>
      </div>
      <div class="col-2">
      <button name="button" type="submit" class="btn btn-outline-danger btn-circle btnRemoveSection">X</button>
      </div>
      </div>
      </div>
      """
    $('.sections_list').append(row)
    $(".sections_list .select2").select2()
