$(document).ready ->
  alphabets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
  
  hide_all = ->
    $("#SBA, #MBA, #MTF, #EMQ, #FTQ").addClass('d-none')

  hide_type = (type) ->
    $('#'+type).removeClass('d-none')

  load_sections = (type) ->
    $.ajax '/app/sections?question_type=' + type,
      type: 'GET'
      dataType: 'json'
      async: false
      error: (jqXHR, textStatus, errorThrown) ->
        console.log("Error")
      success: (data, textStatus, jqXHR) ->
        $("#sections_list").val(JSON.stringify(data))

  reset_section_dropdowns = ->
    sections_list = JSON.parse($("#sections_list").val())
    sections_list.unshift({id: '', text: 'Select Section'})

    $(".sections_list .select2").each (index, element) ->
      $(element).empty().select2({data: sections_list});
  
  process_question_type = ->
    if $("#question_question_type_id").length > 0
      value = $("#question_question_type_id").find(':selected').data('slug')
    else
      value = $("#question_type").data('slug')
    switch 
      when value == 'SBA' then hide_type('SBA')
      when value == 'MBA' then hide_type('MBA')
      when value == 'MTF' then hide_type('MTF')
      when value == 'EMQ' then hide_type('EMQ')
      when value == 'FTQ' then hide_type('FTQ')
      else console.log('Undefined Type')

  hide_all()
  process_question_type()
  load_sections($("#question_type_id").val())

  setTimeout (->
    $('.option_answer_fields:not(.d-none)').each (index, element) ->
      $(element).find('.labelOption').text('Option ' + alphabets[index])
      $(element).find('.correctOption').val(alphabets[index])
  ), 1000

  $(document).on 'change', '#question_question_type_id', (e) ->
    e.preventDefault()
    hide_all()
    process_question_type()
    load_sections($(this).val())
    reset_section_dropdowns()

  $(document).on 'change', '.form-check-input', (e) ->
    e.preventDefault()
    if $("#question_question_type_id").length > 0
      value = $("#question_question_type_id").find(':selected').data('slug')
    else
      value = $("#question_type").data('slug')
    
    if value == 'SBA'
      $(".form-check-input[value='false']").prop("checked",true);
      $(this).prop("checked",true);

  $(document).on 'click', '.btnOptionRemove,.btnOptionAdd', (e) ->
    setTimeout (->
      $('.option_answer_fields:not(.d-none)').each (index, element) ->
        $(element).find('.labelOption').text('Option ' + alphabets[index])
        $(element).find('.correctOption').val(alphabets[index])
    ), 1000
    