// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "@fortawesome/fontawesome-free/js/all";
import 'css/site'

require("bootstrap");
require("trix");
require("@rails/actiontext");

var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import "select2/dist/css/select2.css";
import "select2";

window.Noty = require("../packs/noty.js");

require("../packs/theme.min.js");
require("../packs/dashboard.coffee");
require("../packs/question.coffee");
require("../packs/custom.coffee");

Rails.start();
// Turbolinks.start();
ActiveStorage.start();

$(document).ready(function () {
  $("select").select2({
    width: 'resolve'
  });
  
  $(".select-with-tags").select2({
    tags: true,
  });
});
